import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import SubscriptionComponent from '../components/step-2-form/SubscriptionComponent';
import React, { useState } from 'react';
import demo_video from './../assets/part-payments.mp4';
import poster from './../assets/poster.png';

function Home() {
  const title = 'ACCA';
  const navigate = useNavigate();
  const [step2, setStep2] = useState(0);
  const [name,setName] = useState('');
   const options = [
    'Option 1',
    'Option 2',
    'Option 3',
    // Add more options as needed
  ];

  const handleNameChange = (e) =>{
    const sanitizedValue = e.target.value.replace(/\D+/g, '');
    setName(sanitizedValue);
  }

  const trigger_step2 = (e) => {
    setStep2(1);
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container mb-5 pb-5">

      {step2 === 0 &&
       <div className='row'>
        <div className='col-md-7'>
        <div className="px-4 py-4 my-4">
          <p className='av-font-medium'>Have you opted in for part payment? <a href="https://bit.ly/ACCAContactUs" className='red-a' target='_blank'>Learn more</a></p>
          <h1 className="display-55 fw-bold mt-md-5 pb-2 pt-md-5">
                Please enter your ACCA ID to begin<br/> <small>
                  This is the unique 7 digit number issued to you when you joined ACCA.</small></h1>
          <div className="mx-auto w-100">
            <div className="w-100 d-flex mt-4 d-sm-flex justify-content-sm-center">
              <input placeholder='XXXXXXX' type='text' value={name} className={`text-26 av-font w-100 ${name.length > 7 ? 'border-danger' : ''}`}
              onChange={handleNameChange}/>
              <button type="button" className={`px-4  av-font gap-3 ${name.length == 7 ? 'active' : ''}`} onClick={() => trigger_step2()}>Continue</button>
            </div>
          </div>
        </div>
        </div>
        <div className='col-md-5'>
          <div className='px-4 px-md-5 py-4 my-4 border-left-2'>          
          <p className='text-center av-font-medium'>How to opt in to make part payment?</p>
        <video width="100%" height="auto" poster={poster} controls="false">
        <source src={demo_video} type="video/mp4"/> 
       </video>
       <p className='text-center av-font-medium py-4'> <a className='red-a' href='https://bit.ly/ACCAContactUs' target='_blank'>Opt for part payment.</a></p>
        </div>
       </div>
       </div>
        }
        {step2 === 1 && <SubscriptionComponent user={name}/> }
        <div className="g-signin2" data-onsuccess="handleClick"></div>
      </main>

    </>
  );
}

export default Home;
