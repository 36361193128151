import { NavLink } from 'react-router-dom';

import './navbar.css';
import './footer.css';
import logo from './logo.png';
import banner from './banner.png';
import banner_s from './banner-mobile.png';

function MainNavbar() {
  const items = [
    { path: '/', title: 'Home' },
    { path: '/login', title: 'Login' },
    { path: '/logout', title: 'Logout' },
    { path: '/signup', title: 'Signup' },
  ];

  return (
   <> <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-fluid">
        <a className="navbar-brand d-flex pt-2 px-md-5 mx-md-5" href="/">
          <img src={logo} alt="" className="navbar-logo" />
        </a>
      </div>
    </nav>
      <img src={banner}  srcSet={`${banner_s} 768w, ${banner} 1024w`} className='w-100' data-html2canvas-ignore="true"/>
    </>
  );
}

export default MainNavbar;
