import React, { useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import calendar from './../../assets/calendar.svg';
import arrow_left from './../../assets/left.svg';
import html2pdf from 'html2pdf.js';
import StatusAlert from '../StatusAlert';
import { Button, Modal } from 'react-bootstrap';
import ApiCalendar from 'react-google-calendar-api';
import logo from '../logo.png';
import footerMessageState from '../../appGlobals';
import { SyntheticEvent, } from 'react';
var gapi = window.gapi

const API_KEY = 'AIzaSyB_2YEbxA1SgJDXkkCQ0ks8QX1J9s4f4g8';
// const SCOPES = ['https://www.googleapis.com/auth/calendar'];
const CLIENT_ID = '1076755180721-p7k9jqsije6bbm8c6riql500mmc5o68c.apps.googleusercontent.com';
// var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
// var SCOPES = "https://www.googleapis.com/auth/calendar.events"
const config = {
  "clientId": CLIENT_ID,
  "apiKey": API_KEY,
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config);

const SubscriptionComponent = (props) => {

  const [totalSubscription, setTotalSubscription] = useState();
  const { user } = props;
  const [paymentType, setPaymentType] = useState('');
  const [currency, setCurrency] = useState("GBP");
  const [numberOfPayments, setNumberOfPayments] = useState(2);
  const [paymentRows, setPaymentRows] = useState([{ amount: '', date: '' }]);
  const [validationError, setValidationError] = useState('');
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(null);
  const [eventsAdded, setEventsAdded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventAdded, setEventAdded] = useState(false);
  const [calendarLink, setCalendarLink] = useState('');
  const [totalAmountEntered, setTotalAmountEntered] = useState(0);
  const [eventNotAdded, seteventNotAdded] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
    saveToCSV();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  function formatDate(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  }

  function createICSData(event) {
    const { title, description, start, end } = event;
    const startDate = formatDate(start);
    const endDate = formatDate(end);

    const icsData = `BEGIN:VCALENDAR
  VERSION:2.0
  BEGIN:VEVENT
  SUMMARY:${title}
  DESCRIPTION:${description}
  DTSTART:${startDate}
  DTEND:${endDate}
  END:VEVENT
  END:VCALENDAR`;
    return icsData;
  }

  const loginAddEventLogout = () => {

    apiCalendar.handleAuthClick().then((val) => {
      paymentRows.forEach((val, index) => {
        console.log(val, index);
        let end = new Date(val.date);
        end.setDate(new Date(val.date).getDate() + 1);
        let event = {
          title: 'ACCA Payment Reminder: ' + (index + 1) + getNumberWithOrdinal(index + 1) + " Payment (in GBP)",
          description: 'Total payable amount: ' + val.amount,
          start: new Date(val.date), end: end
        }

        apiCalendar.createEvent({
          summary: 'ACCA Payment Reminder: ' + (index + 1) + getNumberWithOrdinal(index + 1) + " Payment (in GBP): " + val.amount,
          start: {
            dateTime: new Date(val.date),
            // timeZone: "Europe/Paris",
          },
          end: {
            dateTime: end,
            // timeZone: "Europe/Paris",
          }
        })
          .then((result) => {
            console.log(result);
            console.log(result.result.htmlLink);
            setCalendarLink(result.result.htmlLink);
          })
          .catch((error) => {
            console.log(error);
          });

        if (index === paymentRows.length - 1) {
          setEventAdded(true);
          // setShowModal(false);
          // apiCalendar.handleSignoutClick();
        }
      })

    }).catch(val => {
      // seteventNotAdded(true);
      // setShowModal(false);
    })

  };

  const addEventsToCalendar = async () => {
    let events = [];
    paymentRows.forEach((val, index) => {
      console.log(val, index);
      let end = new Date(val.date);
      end.setDate(new Date(val.date).getDate() + 1);
      let event = {
        title: 'ACCA Payment Reminder: ' + (index + 1) + getNumberWithOrdinal(index + 1) + " Payment (in GBP)",
        description: 'Total payable amount: ' + val.amount,
        start: new Date(val.date),
        end: end
      }
      console.log(event);
      events.push(event);
    })

    const combinedICSData = events.map(createICSData).join('\n\n');
    const blob = new Blob([combinedICSData], { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', user + '-payment-plan.ics');
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function calculatePartPayments() {
    // const currentYear = new Date().getFullYear();
    const startDate = new Date();// new Date(`${2023}-11-01`);
    const endDate = new Date(`${2024}-03-31`);
    let monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    monthsDiff -= startDate.getMonth();
    monthsDiff += endDate.getMonth();
    return monthsDiff;
  }

  // Example: Calculate part-payments for the current year
  const numberOfPartPayments = calculatePartPayments();
  console.log(numberOfPartPayments);

  const handleSubscriptionChange = (e) => {
    setTotalSubscription(e.target.value);
    if (paymentType === 'Equal Payment') {
      const equalPaymentAmount = totalSubscription / numberOfPayments;
      const equalPaymentRows = Array.from({ length: numberOfPayments }, (_, index) => ({
        amount: equalPaymentAmount.toFixed(2),
        date: '',
        id: index + 1,
      }));
      setPaymentRows(equalPaymentRows);
    }
  };

  const handlecurrencyChange = (e) => {
    setCurrency(e.target.value);
  }

  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = e.target.value;
    setPaymentType(selectedPaymentType);
    if (selectedPaymentType === 'Equal Payment') {
      const equalPaymentAmount = totalSubscription / numberOfPayments;
      const equalPaymentRows = Array.from({ length: numberOfPayments }, (_, index) => ({
        amount: equalPaymentAmount.toFixed(2),
        date: '',
        id: index + 1,
      }));
      setPaymentRows(equalPaymentRows);
    }
    else {
      const equalPaymentRows = Array.from({ length: numberOfPayments }, (_, index) => ({
        amount: '',
        date: '',
        id: index + 1,
      }));
      setPaymentRows(equalPaymentRows);
    }
  };

  const handleNumberOfPaymentsChange = (e) => {
    const selectedNumberOfPayments = parseInt(e, 10);
    // console.log(selectedNumberOfPayments, 'Set no of p');
    setNumberOfPayments(selectedNumberOfPayments);
    if (paymentType === 'Equal Payment') {
      const equalPaymentAmount = totalSubscription / selectedNumberOfPayments;
      const equalPaymentRows = Array.from({ length: selectedNumberOfPayments }, (_, index) => ({
        amount: equalPaymentAmount.toFixed(2),
        date: '',
        id: index + 1,
      }));
      setPaymentRows(equalPaymentRows);
    }
    else {
      const equalPaymentRows = Array.from({ length: selectedNumberOfPayments }, (_, index) => ({
        amount: '',
        date: '',
        id: index + 1,
      }));
      setPaymentRows(equalPaymentRows);
    }
    setIsDropdownOpen(false);
  };

  const handlePaymentRowChange = (index, field, value) => {
    const updatedRows = [...paymentRows];
    updatedRows[index][field] = value;
    setPaymentRows(updatedRows);

    if (paymentType === 'Flexi Payment') {
      validateTotalAmount(updatedRows);
    }
  };

  const validateTotalAmount = (rows) => {
    const totalAmount = Math.round(rows.reduce((total, row) => total + Number(row.amount || 0), 0));
    if (totalAmount > totalSubscription) {
      setValidationError('The total amount exceeds the annual membership subscription fee.');
    } else {
      setValidationError('');
    }
  };


  const isPaymentRowsValid = () => {
    const sortedRows = [...paymentRows].sort((a, b) => new Date(a.date) - new Date(b.date));

    for (let i = 0; i < sortedRows.length; i++) {
      const row = sortedRows[i];

      // Check if amount and date fields are empty
      if (!row.amount || !row.date) {
        return false;
      }

      // Check if dates are in ascending order
      if (i > 0 && new Date(row.date) < new Date(sortedRows[i - 1].date)) {
        return false;
      }
    }

    return true;
  };

  // Function to check if all row.amount and row.date fields are filled
  const areAllFieldsFilled = () => {
    return paymentRows.every(row => row.amount && row.date);
  };

  // // Function to check if dates are in ascending order
  // const areDatesAscending = () => {
  //   const dates = paymentRows.map(row => row.date);
  //   return dates.every((date, index) => index === 0 || new Date(date) > new Date(dates[index - 1]));
  // };
  // Function to check if sum of row.amount fields matches total annual subscription

  const isTotalAmountMatching = () => {
    const totalAmount = Math.round(paymentRows.reduce((total, row) => total + Number(row.amount || 0), 0));

    return parseFloat(totalAmount) === parseFloat(totalSubscription);
  };
  const downloadAsPDF = () => {
    const element = document.getElementById('divToConvert');
    var clonedElement = element.cloneNode(true);
    // clonedElement.style.display = "block";
    const imgElement = clonedElement.querySelector('img');

    if (imgElement) {
      imgElement.style.display = 'block';
    }

    const options = {
      margin: 0,
      filename: user + '-payment-plan.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    saveToCSV();
    html2pdf().from(clonedElement).set(options).save();
  };
  // Use these functions to conditionally add 'active' class to the button
  const isButtonActive = areAllFieldsFilled() && isTotalAmountMatching();
  const totalAmount = Math.round(paymentRows.reduce((total, row) => total + Number(row.amount || 0), 0));
  const due_balance = totalSubscription - totalAmount;

  const getMaxDate = () => {
    const today = new Date();
    const year = today.getFullYear();

    let month = 3;
    const day = 31;
    const maxDateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    return maxDateString;
  };

  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();

    let month = today.getMonth() + 1;
    const day = today.getDate();
    const maxDateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    return maxDateString;
  };

  const handlePaymentSubmit = () => {
    // console.log(areAllFieldsFilled() && isTotalAmountMatching(),areAllFieldsFilled(),isTotalAmountMatching())
    // Handle submission of payment details
    // Perform further validation or submit logic
    if (isButtonActive) {
      setShowPaymentPlan(true);
      footerMessageState.footer = 1;
    }
    // else {
    //   // alert('Please Enter Required Fields');
    // }
  };

  const saveToCSV = async () => {
    try {
      const response = await fetch('acca.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `userName=${encodeURIComponent(user)}&paymentRows=${encodeURIComponent(JSON.stringify(paymentRows))}`,
      });

      if (response.ok) {
        // CSV data has been saved, you can handle success here
        console.log('CSV data saved successfully');
      } else {
        // Handle error
        console.error('Failed to save CSV data');
      }
    } catch (error) {
      // Handle fetch error
      console.error('Error during CSV data save:', error);
    }
  };

  const editPlan = () => {
    setShowPaymentPlan(false);
    footerMessageState.footer = 0;
  }

  const isDuplicateMonthInPaymentRows = () => {
    const selectedMonths = paymentRows.map(row => new Date(row.date).getMonth());
    const uniqueMonths = new Set(selectedMonths);
    return selectedMonths.length !== uniqueMonths.size;
  };
  console.log(isDuplicateMonthInPaymentRows());

  function getNumberWithOrdinal(n) {
    var s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <div className='row'>
      {!showPaymentPlan && (
        <div className='mt-md-5 mt-2 col-md-10 mx-auto justify-content-center'>
          <div className='row'>
            <div className='col-md-12 d-md-flex justify-content-md-center mx-auto'>
              <div className='d-table-cell d-md-flex  pt-md-3 text-md-end v-middle medium-line' style={{ lineHeight: '60px' }}> Your overdue balance is: &nbsp; &nbsp;</div>
              <label className='d-md-table-cell'>
                <div className='d-flex pt-md-3'>
                  <input type="number" onKeyDown={(e) => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault();
                    }
                  }} placeholder='Enter Amount' className='bold-input me-2' value={totalSubscription} onKeyUp={handleSubscriptionChange} onChange={handleSubscriptionChange} />
                  <p className='d-table-cell pt-3 v-middle text-md-start medium-line'> in <span className='av-font'>GBP</span></p>
                </div>
              </label>
            </div>

            <div className='row'>
              <div className='col-md-12 d-md-flex justify-content-md-center mx-auto'>
                <div className='d-table-cell d-md-flex  pt-md-3 text-md-end v-middle medium-line'> &nbsp; &nbsp;</div>
                {/* need-help */}
                <a href="https://bit.ly/ACCAContactUs" target='_blank' className='text-center mx-auto text-bg-light pt-md-2 btn-last d-md-table-cell py-2 px-4 mt-2'>Check your balance</a>
              </div></div>
          </div>

          <br />
          {totalSubscription > 0 && (
            <div className='mt-2 text-center w-100'>
              <span className='display-66'>How would you like to pay?</span>
              <div>
                {/* <label className='av-font-light mx-2'>
              <input
                type="radio" className='my-3 mx-2'
                value="Flexi Payment"
                id='numberOfPaymentsSelect'
                checked={paymentType === 'Flexi Payment'}
                onChange={handlePaymentTypeChange}
              />
              Flexi Payment
            </label> */}

                <label className='av-font-light custom-radio mx-4 my-3'>
                  <input type="radio"
                    className='my-3 mx-2'
                    value="Flexi Payment"
                    checked={paymentType === 'Flexi Payment'}
                    onChange={handlePaymentTypeChange} />
                  <span className="radio-btn"></span>
                  Flexi Payment
                </label>

                <label className='av-font-light custom-radio mx-4 mt-md-4 my-md-3'>
                  <input type="radio"

                    className='my-3 mx-2'
                    value="Equal Payment"
                    checked={paymentType === 'Equal Payment'}
                    onChange={handlePaymentTypeChange} />
                  <span className="radio-btn"></span>
                  Equal Payment
                </label>


              </div>
            </div>)}
          {paymentType && (
            <div className='box-1 p-md-5 p-2 mt-3'>
              {paymentType == 'Equal Payment' && (
                <div className='bb-1'>
                  <h1 className='label8 my-2 mb-4'>Equal Payment</h1>
                </div>
              )}

              {paymentType == 'Flexi Payment' && (
                <div className='bb-1 mb-2'>
                  <h1 className='label8 my-2'>Flexi payment</h1>
                  <p className='mb-4 pt-2 label9'>Insert the amounts and choose dates for payments.</p>
                </div>
              )}
              {paymentType && (
                <div>
                  <label className='av-font mt-md-2 label10'>
                    How many part-payments would you like to make?
                    {/* <select value={numberOfPayments} className='select-1 my-4 mb-3' onChange={handleNumberOfPaymentsChange}>
                      {Array.from(Array(numberOfPartPayments).keys()).map((num) => (
                        <option value={num + 2} key={num + 2}>
                          {num + 2}
                        </option>
                      ))}
                    </select> */}

                    <div className="custom-select-container mt-4">
                      <div
                        className={`selected-option ${isDropdownOpen ? 'open' : ''}`}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        {numberOfPayments}
                      </div>
                      {isDropdownOpen && (
                        <ul className="options-list">
                          {Array.from(Array(numberOfPartPayments).keys()).map((num) => (
                            <li key={num+2} onClick={() => handleNumberOfPaymentsChange(num + 2)} className={`${num+2 === numberOfPayments ? 'active' : ''}`}>
                              {num + 2}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                  </label>
                  <p className='py-3 pb-0 mb-0 mt-3 av-font label10'>How would you like to split the payment?</p>
                  {paymentRows.map((row, index) => (
                    <div key={index} className='py-2'>
                      <span className='mx-1'>{index + 1}<sup>{getNumberWithOrdinal(index + 1)}</sup> Payment (in {currency})</span>
                      <input
                        type="text"
                        placeholder="Enter Amount"
                        className='mx-2 row-input'
                        value={row.amount}
                        onChange={(e) => handlePaymentRowChange(index, 'amount', e.target.value)}
                        readOnly={paymentType === 'Equal Payment'}
                      />
                      <span className='mx-2'>on</span>
                      <input
                        type="date"
                        placeholder='Choose Date'
                        onKeyDown={(e) => {
                          if (e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault();
                          }
                        }}
                        max={getMaxDate()}
                        min={getMinDate()}
                        className='mx-2 row-input'
                        value={row.date}
                        onChange={(e) => handlePaymentRowChange(index, 'date', e.target.value)}
                      // readOnly={paymentType === 'Equal Payment'}
                      />
                    </div>
                  ))}

                  {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
                  {isDuplicateMonthInPaymentRows() && <p style={{ color: 'red' }}>Only one EMI allowed in a month.</p>}
                  {!validationError && !isButtonActive && totalAmount > 0 && <p className='av-font py-4'>Balance Amount: {Math.round(due_balance)}</p>}
                  {isButtonActive && <p style={{ color: 'darkgreen' }} className='av-font display-6 text-16 py-4'>Total Amount in (GBP): {totalSubscription}</p>}
                  <button onClick={handlePaymentSubmit} className={`px-4 text-16 my-2 gap-3 ${isButtonActive && !isDuplicateMonthInPaymentRows() ? 'active' : ''}`}>Show my payment plan</button>
                </div>
              )}
            </div>

          )}
        </div>)}
      {showPaymentPlan && (
        <div className='container'>
          {/* <div className='row'>
          </div> */}
          <div className='row mx-auto justify-content-center mt-4 pt-4 mx-md-5 py-5' id='divToConvert'>
            <div className='col-md-12'>
              <div className='d-block py-2'>
                <img src={logo} alt="" className="navbar-logo" style={{ display: 'none' }} />
              </div>
            </div>
            <div className='col-md-12 py-3'>
              {/* <h3 className='py 2'>Dear {user},</h3> */}
              <h2 className='text-center congrat'>Hey, ({user})</h2>
              <p className='text-center congrat-sub'>Here is your personalized part payment plan</p>
            </div>
            {paymentRows.map((row, index) => (
              <div className='col-md-3 mt-4'>
                <div className='border-1px py-3 pt-0'>
                  <p className='text-center plan-line-1 bg-gray py-3 text-capitalize'>{index + 1}<sup className='text-lowercase'>{getNumberWithOrdinal(index + 1)}</sup> Payment</p>
                  <p className='text-center mt-5'><span className='plan-amount'>{row.amount}</span> {currency}</p>
                  <hr className='mx-auto'
                    style={{
                      height: 1, width: '80%'
                    }}
                  />
                  <p className='text-center mb-5'><span><img src={calendar} /></span> {row.date}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='row mx-md-5' data-html2canvas-ignore="true">
            <div className='d-flex justify-content-start mx-auto col-md-6'>
              <button onClick={editPlan} className='mx-2 py-3 edit-plan border-bottom-dashed pb-0 mx-0 px-0'><span><img src={arrow_left} /></span>&nbsp;&nbsp;Edit Your Plan</button>
            </div>

            <div className='d-flex justify-content-end mx-auto col-md-6'>
              {/* <GoogleOAuthProvider clientId="456324307089-k4b1pmsfd7ufjh448s8f6k93j1demfeg.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={handleAuthClick}
            />
          </GoogleOAuthProvider> */}
              <button onClick={downloadAsPDF} className='mx-2 download-plan btn-last px-4'>Download Plan</button>
              <button onClick={handleShowModal} className='active btn-last px-4'>Add Events to Calendar</button>

              {/* <button onClick={handleAddToCalendar}>Add my plan to my calendar</button> */}
            </div>
            <div className='col-12' data-html2canvas-ignore="true">
              <p className='text-center know-more py-5 my-4'><a href="https://bit.ly/ACCAContactUs" target="_blank">Click here</a> to know more about the payment process</p>
            </div>
          </div>
        </div>
      )}



      <Modal data-html2canvas-ignore="true" show={showModal} className='mt-5' onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title className='av-font'>Handle Your Calendar</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <p className='av-font pt-3'>iCalendar File</p> */}
          <div className='justify-content-center'>
            <button onClick={addEventsToCalendar} className='active btn-last d-block justify-content-center mx-auto px-4 my-4 py-2'>Download iCalendar</button>
            <button onClick={loginAddEventLogout} className='active btn-last d-block justify-content-center mx-auto py-2 px-4 my-4'>Add Events to Google Calendar</button>
          </div>
          {eventAdded && <p className='av-font text-center py-2'>Event(s) Added to calendar. <a className='read-more learn-more' href={calendarLink} target='_blank'>Follow Link</a></p>}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <StatusAlert show={eventAdded} message={"Event(s) Added To Your Google Calendar!"} />
    </div>
  );
};

export default SubscriptionComponent;