import facebook from '../assets/Facebook.png';
import linkedin from '../assets/LinkedIn.png';
import youtube from '../assets/YouTube.png';
import x from '../assets/X.png';
import { useState } from 'react';
import footerMessageState from "../appGlobals";

function Footer() {
  const [messageType, setMessageType] = useState(0);

  return (
    <footer className="my-5 px-md-5 text-center text-muted small"  data-html2canvas-ignore="true">
      <div className="container">
     <div className='row'>
     <div className='col-md-7'>

     <p className='text-start mb-0'>{footerMessageState.footer}Last date for payment: <b>31st March, 2024</b></p>
    
      <p className='badge bg-dark-2 text-wrap footer-text-2 mb-0 text-black text-16 text-start w-100'>This widget is a payment reminder and cannot be used to make any payment.</p>
      <div className="footer-links">
        <a href="https://www.accaglobal.com/in/en.html">©2023 ACCA Global</a>
        <a href='https://www.accaglobal.com/in/en/footertoolbar/access.html'>Accessibility</a>
        <a href='https://www.accaglobal.com/in/en/footertoolbar/legal/acca-legal-and-copyright-statement.html'>Legal policies</a>
        <a href='https://www.accaglobal.com/in/en/footertoolbar/privacy/acca-website-privacy-promise.html'>Data protection & cookies</a>
      </div>
      </div> 
      <div className='col-md-5'>
      <div className="social-links">
        <a href="https://www.linkedin.com/school/acca/" target='_blank'><img src={linkedin}/> </a>
         <a href='https://twitter.com/ACCANews' target='_blank'><img src={x}/></a>
          <a href='https://www.youtube.com/user/ACCAOfficial' target='_blank'><img src={youtube}/></a>
          <a href='https://www.facebook.com/ACCA.Official' target='_blank'><img src={facebook}/></a>
      </div>
      </div>
     </div>
      </div>
    </footer>
  );
}

export default Footer;
